import Dropdown from "react-bootstrap/Dropdown";
import logo from "../images/icons/logo.svg";
import copy from "../images/icons/copy-icon.svg";
import brand from "../images/icons/brand-icon.svg";
export default function BrandDropdown() {
  return (
    <div className="brand-dropdown-wrapper">
      <Dropdown className="brand-dropdown">
        <Dropdown.Toggle>
          <img src={logo} alt="logo" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="logo-dropdown">
          <Dropdown.Item>
            <div className="single-item">
              <div className="icon">
                <img src={copy} alt="icon" />
              </div>
              <span>Copy</span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item>
            <div className="single-item">
              <div className="icon">
                <img src={brand} alt="icon" />
              </div>
              <span>Brand</span>
            </div>
          </Dropdown.Item>
          
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
