import SecondaryFooter from "../components/SecondaryFooter";
import SecondaryHeader from "../components/SecondaryHeader";
import arrowRightBlack from "../images/icons/arrow-right-black.svg";
import arrowRight from "../images/icons/arrow-right.svg";
import { Link } from "react-router-dom";
import signUpBanner from "../images/home-new-banner.svg";
import signUpBannerDark from "../images/site-map-img.svg";

export default function HomepageNew() {
  return (
    <>
      <SecondaryHeader />
      <div className="signup-page-wrapper ">
        <div className="page-content container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <div className="content-wrapper">
                <div className="title">
                  <h1>
                  Build your own <br /> <span>school to scale</span>
                  </h1>
                </div>
                <p className="description">
                Ilmiya provides educators with the infrastructure they need to build and profitably scale their learning programs—all in one place.
                </p>
              </div>
              <div className="buttons-wrapper">
                <div className="login-button ">
                  <Link to={"#"}>
                    Explore the Platform
                    <img src={arrowRight} alt="arrow icon" />
                  </Link>
                </div>
                <div className="login-options">
                  <div className="single-option homepage-new-button">
                    <Link to={"/contact-us"}>
                      Get Started
                      <img src={arrowRightBlack} alt="arrow icon" />
                    </Link>
                  </div>
                  {/* <div className="single-option">
                    <Link to={"#"}>
                      For Publishers
                      <img src={arrowRightBlack} alt="arrow icon" />
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="signup-banner">
                <img
                  src={signUpBanner}
                  alt="banner"
                  className="light-theme-img"
                />
                <img
                  src={signUpBanner}
                  alt="banner"
                  className="dark-theme-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SecondaryFooter />
    </>
  );
}
