import "./App.css";
import AboutUsPage from "./assets/pages/AboutUs";
import ContactUsPage from "./assets/pages/ContactUs";
import HomePage from "./assets/pages/HomePage";
import "./assets/scss/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignupPage from "./assets/pages/SignupPage";
import LegalPage from "./assets/pages/LegalPage";
import LegalDetailPage from "./assets/pages/LegalDetail";
import NotFound from "./assets/pages/NotFound";
import ThankYouPage from "./assets/pages/ThankYou";
import HomepageNew from "./assets/pages/HomePageNew";
import RouterPage from "./assets/pages/Router";
import Usagepolicy from "./assets/pages/Usagepolicy";
import Dataprotection from "./assets/pages/Dataprotection";
import Privacypolicy from "./assets/pages/Privacypolicy";
import Termsofprivacy from "./assets/pages/Termsofprivacy";
function App() {
  return (
    
    <>
    <Router>
            <Routes>
                <Route path="/" element={<HomepageNew />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/legal" element={<LegalPage />} />
                <Route path="/legal-detail" element={<LegalDetailPage />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/thank-you" element={<ThankYouPage />}/>
                <Route path="/router" element={<RouterPage />}/>
                <Route path="/usage-policy" element={<Usagepolicy />}/>
                <Route path="/data-protection" element={<Dataprotection />}/>
                <Route path="/privacy-policy" element={<Privacypolicy />}/>
                <Route path="/terms-of-services" element={<Termsofprivacy />}/>
            </Routes>
        </Router>
      
    </>
  );
}

export default App;
